
.main-section {
    position: relative;
    background-color: #070707;
    z-index: 1;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -50vw;
        width: 150vw;
        height: 30%;
        background-color: var(--v-grey-darken4);
        z-index: -1;
    }
}
.gradient-bg {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 150vw;
        height: 100%;
        background: linear-gradient(rgba(153, 153, 153, 0) 0%, rgba(153, 153, 153, 1) 50%, rgba(153, 153, 153, 0) 100%);
        opacity: 0.2;
    }
}
.gradient-bg2 {
    position: relative;
    z-index: 1;
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -50vw;
        width: 200vw;
        height: 100%;
    }
    &::before {
        background-color: var(--v-grey-darken4);
        z-index: -2;
    }
    &::after {
        background: linear-gradient(rgb(7, 7, 7) 0%, rgba(7, 7, 7, 0) 40%);
        z-index: -1;
    }
}
.professionalism-bg {
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: calc(-1 * var(--container-gutter));
        width: calc(100% + var(--container-gutter) * 2);
        height: 100%;
        background: url(/images/main/professionalism-bg-mobile.jpg) no-repeat center;
        background-size: 100%;
        background-position: top;
        z-index: -1;
    }
}
@media (min-width: 768px) {
    .professionalism-bg {
        background: url(/images/main/professionalism-bg.jpg) no-repeat center;
        background-size: contain;
        &::before {
            display: none;
        }
    }
}
.border-wrap {
    border-top: 1px solid var(--v-grey-base);
    border-bottom: 1px solid var(--v-grey-base);
    padding: var(--grid-gutter) 0;
}
.main-professionalism-list {
    > li {
        padding-top: 60px;
    }
}
@media (min-width: 768px) {
    .main-professionalism-list {
        > li {
            padding: 80px 0;
            &:first-child {
                padding: 100px 0;
            }
        }
    }
}

.full-width {
    position: relative;
    aspect-ratio: 360 / 280;
    left: calc(-1 * var(--container-gutter));
    width: 100vw;
    background: url(/images/main/professionalism-2-mobile.png) no-repeat;
    background-size: contain;
}

.check-list {
    &__title {
        position: relative;
        display: inline-block;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 4px;
            height: 4px;
            background-color: #fff;
            border-radius: 50%;
        }
        &::before {
            left: -8px;
        }
        &::after {
            right: -8px;
        }
    }
    &__card {
        background-color: rgba(255, 255, 255, 0.2);
        height: 90px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        color: #fff;
    }
}
@media (min-width: 768px) {
    .check-list {
        &__card {
            padding: 0 32px;
        }
    }
}
