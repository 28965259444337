
// btn-top-primary
.btn-top-primary {
    bottom: var(--btn-top-position-bottom) !important;
    right: var(--btn-top-position-right) !important;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    -webkit-transition: all ease-out 0.2s;
    -ms-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;
    will-change: unset !important;
    z-index: 9999;
    background-color: #000 !important;
    border: 1px solid rgba(255,255,255,.2);
    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
