
.tit-wrap {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    background: url(/images/main/main-title-mobile.png) no-repeat center;
    background-size: cover;
    padding: 24px 0;
    .tit-edge {
        background-color: #B58E6B;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100%;
        transform: translate(-50%, 23px);
    }
}
