
.iCountUp {
    text-align: right;
    display: inline-block;
    min-width: 44px;
}
@media (min-width: 768px) {
    .iCountUp {
        min-width: 98px;
    }
}
@media (min-width: 1024px) {
    .iCountUp {
        min-width: 134px;
    }
}
