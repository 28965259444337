
.header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    -webkit-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;

    // bg
    &__bg {
        display: none;
    }

    // fixed
    &--fixed {
        position: fixed;
    }
}
@media (min-width: 1200px) {
    .header {
        // bg
        &__bg {
            position: fixed;
            top: var(--header-height);
            left: 0;
            z-index: 1;
            width: 100vw;
            min-height: var(--header-bg-height);
            opacity: 0;
            visibility: hidden;
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }

        // light
        &--light {
            color: #fff;
            ::v-deep {
                .user-items,
                .language-items,
                .gnb__link {
                    color: #fff;
                }
            }
        }
        // dark
        &--dark {
            color: var(--v-grey-darken4);
            ::v-deep {
                .user-items,
                .language-items,
                .gnb__link {
                    color: var(--v-grey-darken4);
                }
            }
        }

        // lnb-all
        &--lnb-all {
            ::v-deep {
                .lnb {
                    top: var(--header-body-height);
                    min-width: auto;
                    min-height: var(--header-bg-height);
                    height: auto;
                }
            }
            .header {
                &__bg {
                    display: block;
                }
            }
            // lnb-all gnb-active
            &.gnb-active {
                ::v-deep {
                    .header__bg,
                    .lnb {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}
