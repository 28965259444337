
.main-visual-body {
    padding-top: var(--header-height);
}
.border-label {
    display: inline-block;
    padding: 8px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: var(--v-primary-lighten2);
}
.iCountUp {
    text-align: right;
    display: inline-block;
    min-width: 78px;
}
@media (min-width: 768px) {
    .iCountUp {
        min-width: 178px;
    }
}
