
.header {
    ::v-deep {
        .logo {
            > a {
                background-image: url(/images/logo-light.svg);
            }
        }
        &:hover {
            .logo {
                > a {
                    background-image: url(/images/logo-dark.svg);
                }
            }
        }
    }
}
