
.lnb--all-opacity-black ::v-deep {
    line-height: 1.35;
    padding: 32px 0;
    background-color: transparent;
    > li {
        > a {
            font-weight: 400;
            color: rgba(255, 255, 255, 0.7);
            padding: 8px;
            &:hover {
                // color: rgba(255, 255, 255, 1);
                color: var(--v-primary-base);
            }
        }
    }
}
