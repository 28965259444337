
.border-title {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    padding: 16px 0;
}
@media (min-width: 768px) {
    .border-title {
        border: none;
        padding: 0;
    }
}
