
.iCountUp {
    text-align: right;
    display: inline-block;
    min-width: 42px;
}
@media (min-width: 768px) {
    .iCountUp {
        min-width: 94px;
    }
}
@media (min-width: 1024px) {
    .iCountUp {
        min-width: 126px;
    }
}
