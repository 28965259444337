
.review-card {
    background-color: white;
    &__image {
        background-position-x: center !important;
        background-position-y: top !important;
    }
}
::v-deep {
    .v-dialog {
        width: auto;
    }
}
@media (min-width: 1200px) {
    .review-card {
        overflow: hidden;
        position: relative;

        &::after,
        &__overlay {
            position: absolute;
            opacity: 0;
            transition: all ease-out 0.3s;
        }
        &::after {
            content: "";
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            background-color: var(--v-primary-base);
        }
        &__overlay {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }

        &:hover {
            &::after {
                opacity: 0.8;
            }
            .review-card__overlay {
                opacity: 1;
            }
        }
    }
}

.review-dialog {
    position: relative;
    overflow: visible;
    .close-btn {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        width: 100%;
    }
}
