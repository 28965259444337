
// main-visual
.main-visual {
    width: 100%;
    &__inner {
        display: block;
        max-width: 1920px;
        width: 100%;
        height: 100%;
        margin: auto;
    }
    &__img {
        max-width: 100%;
        width: 100%;
        padding-top: calc(1180 / 750 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.25);
    }
}

// .iCountUp {
// }
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        &__img {
            padding-top: 0;
            height: 700px;
        }
    }
}
@media (min-width: 1024px) {
}
