
.floating-buttons--pc {
    position: fixed;
    z-index: 99;
    bottom: 12px;
    right: 12px;
    > li {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
@media (min-width: 1500px) {
    .floating-buttons--pc {
        bottom: 40px;
        right: 40px;
    }
}
.floating-buttons--mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 99;
    width: 100vw;
    height: 60px;
    .button {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    border-top: 1px solid var(--border-color);
}

.v-application .top-btn.v-btn.v-btn:not(.v-btn--has-bg) {
    background-color: #000 !important;
    color: #fff !important;
    border: 1px solid rgba(255,255,255,.2);
}
