
.main-section {
    position: relative;
    padding-top: 134px;
    ::v-deep {
        > .container {
            position: static;
        }
    }
}
@media (min-width: 768px) {
    .main-section {
        padding: var(--page-section-padding-y) 0;
    }
}
