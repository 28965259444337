
.main-section {
    background-image: url(/images/main/review/review-bg-mobile.jpg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    background-color: #2e2623;
}
@media (min-width: 768px) {
    .main-section {
        background-image: url(/images/main/review/review-bg.jpg);
        background-size: cover;
    }
}
