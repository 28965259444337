
.main-section {
    background-image: url(/images/main/main-introduction-mobile.jpg);
    background-size: 100%;
    background-color: #070707;
    padding-bottom: 24px;
    max-width: 1920px;
    margin: 0 auto;
}
@media (min-width: 768px) {
    .main-section {
        padding: var(--page-section-padding-y) 0;
    }
}
@media (min-width: 1024px) {
    .main-section {
        background-image: url(/images/main/main-introduction.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}
