
.v-card {
    border-bottom: 1px solid var(--v-primary-darken4) !important;
}
@media (min-width: 1200px) {
    .v-card {
        &__image {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4);
                opacity: 0;
                transition: opacity ease-out 0.3s;
            }
        }
        &:hover {
            .v-card__image {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}
