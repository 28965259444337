
.v-sheet {
    max-width: 272px;
    margin: auto;
}
.swiper-container {
    overflow: visible;
    .swiper-control {
        width: calc(100vw - var(--container-gutter) * 2);
        max-width: 332px;
        top: 50%;
        transform: translate(-50%, -100%);
        height: auto;
        .swiper-button-prev,
        .swiper-button-next {
            position: relative !important;
            top: initial;
            left: initial;
            right: initial;
            bottom: initial;
            margin: 0;
            &::after {
                display: none;
            }
        }
    }
}
@media (min-width: 768px) {
    .v-sheet {
        max-width: 580px;
    }
    .swiper-container {
        .swiper-control {
            transform: translate(-50%, -75%);
            max-width: 662px;
        }
    }
}
@media (min-width: 1920px) {
    .swiper-container {
        .swiper-control {
            width: calc(100vw + 42px * 2);
            max-width: 1866px;
        }
    }
}
