
.mobile-nav {
    position: fixed;
    z-index: 100;
    &,
    &__contents,
    &__overlay {
        transition: all ease-out 0.2s;
    }
    &__contents {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 100;
        display: block;
        width: 100%;
        height: 100vh;
        max-height: 100vh;
        max-width: 204px;
        background: #fff;
        overflow-x: hidden;
        overflow-y: auto;
        transform: translateX(-100%);
    }
    &__overlay {
        position: fixed;
        top: 0;
        right: 0;
        z-index: -90;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 0;
        visibility: hidden;
    }
    &.open {
        .mobile-nav {
            &__contents {
                transform: translateX(0);
                transition: all ease-out 0.2s;
            }
            &__overlay {
                z-index: 90;
                opacity: 1;
                visibility: visible;
                transition: all ease-out 0.2s;
            }
        }
    }
}
