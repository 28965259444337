
::v-deep {
    .gnb--basic {
        .gnb {
            &__item {
                &.active,
                &:hover {
                    .gnb__link {
                        color: var(--v-primary-base);
                    }
                }
            }
            &__link {
                color: #fff;
                -webkit-transition: all ease-out 0.2s;
                -ms-transition: all ease-out 0.2s;
                transition: all ease-out 0.2s;
            }
        }
    }
}
