
.full-width {
    width: 100vw;
    position: relative;
    left: calc(-1 * var(--container-gutter));
}
@media (min-width: 768px) {
    .main-section {
        max-width: 1920px;
        margin: auto;
        background-image: url(/images/main/cost/cost-bg.jpg);
        background-repeat: no-repeat;
        background-position-x: center;
    }
}
