
.main-section {
    background-color: var(--v-primary-lighten5);
    background-image: url(/images/main/information/information-bg-mobile.jpg);
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: 100%;
    padding-top: 110px;
    ::v-deep {
        .container > .tit-wrap {
            background: none;
            > .tit-edge {
                display: none;
            }
        }
    }
}
@media (min-width: 768px) {
    .main-section {
        padding-top: var(--page-section-padding-y);
        max-width: 1920px;
        margin: auto;
        background-image: url(/images/main/information/information-bg.jpg);
        background-size: initial;
    }
}
iframe {
    aspect-ratio: 16 / 9;
}
