
.swiper-control {
    position: static;
    transform: initial;
    padding-top: 24px;
    .swiper-pagination-fraction {
        font-size: 2rem;
        color: rgba(255, 255, 255, 0.4);
        ::v-deep {
            .swiper-pagination-current {
                font-weight: 700;
                font-size: 3.6rem;
                color: #fff;
                margin-right: 8px;
                vertical-align: -6px;
            }
            .swiper-pagination-total {
                margin-left: 8px;
            }
        }
    }
    .swiper-pagination-progressbar {
        position: relative;
        width: 100%;
        height: 2px;
        background-color: rgba(255, 255, 255, 0.3);
    }
    ::v-deep {
        .swiper-pagination-progressbar-fill {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transform: scale(0);
            transform-origin: left top;
            background-color: rgb(255, 255, 255);
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: relative !important;
        top: initial;
        left: initial;
        right: initial;
        bottom: initial;
        margin: 0;
        &::after {
            display: none;
        }
    }
    &__divider {
        width: 1px;
    }
}
@media (min-width: 1024px) {
    .slide-wrap {
        padding-top: 26px;
    }
    .swiper-control {
        padding-top: 60px;
    }
}
@media (min-width: 1200px) {
    .slide-wrap {
        width: 150%;
    }
    .swiper-control {
        max-width: 800px;
    }
}
